import * as types from "../../constants";

const initialState = {
  votes: [],
  formsData: [],
  editIndex: '',
  question: 'Question',
  description: 'Resolution',
  fields: [],
  eventId: '',
  result: [],
  liveVoting: [],
  voteResults: [],
  error: ''
}

export default function reducer(state = initialState, actions) {
    switch (actions.type) {
        case types.GET_ALL_VOTES_SUCCESS: {
            return {
                ...state,
                votes: actions.votes
            };
        }
        case types.ADD_VOTE_FIELD_SUCCESS: {
            return {
                ...state,
                formsData: actions.formsData
            };
        }
        case types.ENABLE_EDIT_SUCCESS: {
            return {
                ...state,
                editIndex: actions.editIndex
            };
        }
        case types.DELETE_FIELD_SUCCESS: {
            return {
                ...state,
                formsData: actions.formsData
            };
        }
        case types.CREATE_FORM_SUCCESS: {
            return {
                ...state,
                fields: actions.fields
            };
        }
        case types.QUESTION_SUCCESS: {
            return {
                ...state,
                question: actions.question
            };
        }
        case types.DESCRIPTION_SUCCESS: {
            return {
                ...state,
                description: actions.description
            };
        }
        case types.SAVE_FORM_SUCCESS: {
            return {
                ...state,
                eventId: actions.eventId
            };
        }
        case types.GET_VOTE_RESULT_SUCCESS: {
            return {
                ...state,
                result: actions.result
            };
        }
        case types.DELETE_VOTE_SUCCESS: {
            return {
                ...state,
                votes: actions.votes
            };
        }
        case types.SET_ERROR_SUCCESS: {
          return {
            ...state,
            error: actions.error
          };
        }
        case types.GET_LIVE_VOTING_SUCCESS: {
          return {
            ...state,
            liveVoting: actions.liveVoting,
            voteResults: actions.voteResults
          };
        }
        default: {
            return state
        }
    }
}
