import * as types from "../../constants";

const initialState = {
  events: [],
  event: "",
  initialValues: {
    title: "",
    description: "",
    event_url: "",
    permission: "",
    description_object: "",
    login_page_content: "",
    login_page_content_object: ""
  },
  loginStatus: "",
  URLStatus: "",
  imageUrl: "",
  result: [],
  streamFormat: [],
  encodingTemplate: [],
  rtmp: [],
  webhook: [],
  roomId: '',
  chats: '',
  replyToChatData: '',
  showReplyInput: false,
  users: [],
  privateChatStatus: false,
  redirect: "",
  videoPlayerOptions: {
    src: '',
    controls: true,
    autoplay: false,
    preload: true,
    muted: false,
    status: '',
  },
  onGoingVideo: '',
  user: '',
  streamKeys: [],
  notifications: []
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
     case types.CREATE_EVENT_SUCCESS: {
      return {
        ...state,
        events: actions.events,
        imageUrl: ""
      };
    }
    case types.GET_ALL_EVENTS_SUCCESS: {
      return {
        ...state,
        events: actions.events
      };
    }
    case types.UPDATE_EVENTS_VALUES_SUCCESS: {
      return {
        ...state,
        initialValues: actions.initialValues
      };
    }
    case types.LOGIN_SUCCESS: {
      return {
        ...state,
        loginStatus: actions.loginStatus
      };
    }
    case types.LOGIN_FAILURE: {
      return {
        ...state,
        loginStatus: actions.loginStatus
      };
    }
    case types.GET_EVENT_SUCCESS: {
      return {
        ...state,
        event: actions.event
      };
    }
    case types.IMAGE_UPLOADED_SUCCESS: {
      return {
        ...state,
        imageUrl: actions.imageUrl
      };
    }
    case types.GET_VOTE_RESULT_SUCCESS: {
      return {
        ...state,
        event: actions.event
      };
    }
    case types.STREAM_FORMAT_SUCCESS: {
      return {
        ...state,
        streamFormat: actions.streamFormat
      };
    }
    case types.ENCODING_TEMPLATE_SUCCESS: {
      return {
        ...state,
        encodingTemplate: actions.encodingTemplate
      };
    }
    case types.RTMP_SUCCESS: {
      return {
        ...state,
        rtmp: actions.rtmp
      };
    }
    case types.WEBHOOK_SUCCESS: {
      return {
        ...state,
        webhook: actions.webhook
      };
    }
    case types.UPDATE_FEATURE_STATUS_SUCCESS: {
      return {
        ...state,
        events: actions.events
      };
    }
    case types.SET_ROOM_ID: {
      return {
        ...state,
        roomId: actions.roomId
      };
    }
    case types.SET_PRIVATE_CHAT_STATUS: {
      return {
        ...state,
        privateChatStatus: actions.privateChatStatus
      };
    }
    case types.GET_ALL_CHATS_SUCCESS: {
      return {
        ...state,
        chats: actions.chats
      };
    }
    case types.SHOW_REPLY_INPUT: {
      return {
        ...state,
        replyToChatData: actions.replyToChat,
        showReplyInput: actions.status
      };
    }
    case types.DELETE_CHAT: {
      return {
        ...state,
        chats: actions.chats,
        deleteRecord: false
      };
    }
    case types.ENABLE_DELETE_CHAT: {
      return {
        ...state,
        deleteRecord: true
      };
    }
    case types.GET_ALL_USERS_SUCCESS: {
      return {
        ...state,
        users: actions.users
      };
    }
    case types.GET_EVENT_FAILURE: {
      return {
        ...state,
        redirect: actions.redirect
      };
    }
    case types.DELETE_RTMP_SUCCESS: {
      return {
        ...state,
      };
    }
    case types.DELETE_EVENT_SUCCESS: {
      return {
        ...state,
        events: actions.events
      };
    }
    case types.DELETE_VOTE_SUCCESS: {
      return {
        ...state,
        event: actions.event
      };
    }
    case types.SAVE_VIDEO_SUCCESS: {
      return {
        ...state,
        onGoingVideo: actions.onGoingVideo,
        videoPlayerOptions: actions.videoPlayerOptions
      };
    }
    case types.UPDATE_VIDEO_PLAYER_OPTIONS_SUCCESS: {
      return {
        ...state,
        videoPlayerOptions: actions.videoPlayerOptions
      };
    }
    case types.ONGOING_VIDEO_SUCCESS: {
      return {
        ...state,
        onGoingVideo: actions.onGoingVideo,
        videoPlayerOptions: actions.videoPlayerOptions
      };
    }
    case types.GET_USER_SUCCESS: {
      return {
        ...state,
        user: actions.user
      };
    }
    case types.GET_ALL_STREAM_KEYS_SUCCESS: {
      return {
        ...state,
        streamKeys: actions.streamKeys
      };
    }
    case types.GET_ALL_NOTIFICATIONS: {
      return {
        ...state,
        notifications: actions.notifications
      };
    }
    case types.APPEND_QUESTION_ID_TO_EVENT_SUCCESS: {
      return {
        ...state,
        event: actions.event
      };
    }
    default: {
      return state
    }
  }
}
