import * as types from "../../constants";

const initialState = {
  emailVariables: [],
  emailVariable: '',
  emailTemplates: []
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.GET_ALL_EMAIL_VARIABLES_SUCCESS: {
      return {
          ...state,
          emailVariables: actions.emailVariables
      };
    }
    case types.GET_EMAIL_VARIABLE_SUCCESS: {
      return {
          ...state,
          emailVariable: actions.emailVariable
      };
    }
    case types.GET_ALL_EMAIL_TEMPLATES_SUCCESS: {
      return {
        ...state,
        emailTemplates: actions.emailTemplates
      };
    }
    default: {
      return state
    }
  }
}
