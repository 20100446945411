import axios from "axios";

export function createEmailVariables(data, token) {
  return new Promise((resolve, reject) => {
    console.log(data, token)
    axios
      .post(`${process.env.REACT_APP_FORMS_API}/api/email/emailVariables`, data, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("errior", error.response);
        reject(error);
      });
  });
}

export function updateEmailVariables(id, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_FORMS_API}/api/email/emailVariables/${id}`, data, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((response) => {

        if (response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export const getAllEmailVariables = (token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_FORMS_API}/api/email/emailVariables`, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }

      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  })
}

export function getEmailVariablesById(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_FORMS_API}/api/email/emailVariables/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function createEmailTemplate(data, token) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_FORMS_API}/api/email/template`, data, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Berar ${token}`
        },
      })
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("errior", error.response);
        reject(error);
      });
  });
}

export function updateEmailTemplate(id, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_FORMS_API}/api/email/template/${id}`, data, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((response) => {

        if (response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function getEmailTemplates(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_FORMS_API}/api/email/template`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Berar ${token}`
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function getEmailTemplatesByEvent(event_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_FORMS_API}/api/email/template/byevent/${event_id}`, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function sendEmail(data, token) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_FORMS_API}/api/email/send`, data, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Berar ${token}`
        },
      })
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("errior", error.response);
        reject(error);
      });
  });
}