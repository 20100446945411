import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { SnackbarProvider } from 'notistack';
import App from "./App";
import './index.css'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Provider } from "react-redux";
import store from "./redux/store/index";
const muiTheme = createTheme({
  palette: {
    primary: {
      light: '#ffcf45',
      main: '#ffcf45',
      dark: '#ffcf45',
      contrastText: '#233044',
    },
    secondary: {
      light: '#233044',
      main: '#233044',
      dark: '#233044',
      contrastText: '#fff',
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={muiTheme}>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
