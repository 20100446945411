import axios from "axios";

export function getAllOrganisers() {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_LIVESTREAM_API}/api/users`, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function getAllSubscriptions() {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_LIVESTREAM_API}/api/subscriptions`, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function updateSubscriptions(id, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/${id}`, data, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function deleteOrganiser(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/${id}`, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function startAMS() {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_RTMP_API}/ams`, {}, {
        headers: {
          "x-api-key": process.env.REACT_APP_RTMP_API_KEY
        },
      })
      .then((response) => {

        if (response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function stopAMS() {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${process.env.REACT_APP_RTMP_API}/ams`, {
        headers: {
          "x-api-key": process.env.REACT_APP_RTMP_API_KEY
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function getStatusOfAMS() {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_RTMP_API}/ams/status`, {}, {
        headers: {
          "x-api-key": process.env.REACT_APP_RTMP_API_KEY
        },
      })
      .then((response) => {

        if (response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function generateAMSStreamKey(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_RTMP_API}/ams/stream`, data, {
        headers: {
          "x-api-key": process.env.REACT_APP_RTMP_API_KEY
        },
      })
      .then((response) => {

        if (response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function getAMSStreamKeyOfEvent(eventId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_RTMP_API}/ams/stream?eventID=${eventId}`, {
        headers: {
          "x-api-key": process.env.REACT_APP_RTMP_API_KEY
        },
      })
      .then((response) => {

        if (response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}