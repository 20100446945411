/* eslint-disable prettier/prettier */
import React from "react";
import async from "../components/Async";
import {
  Users,
  Sunrise,
  Mail,
  Layers,
  Youtube,
  DollarSign
} from "react-feather";

const AuthGuard = async(() => import("../components/AuthGuard"));

// Auth components
const SignIn = async(() => import("../view/auth/Signin"));
const SignUp = async(() => import("../view/auth/Signup"));
const ForgotPassword = async(() => import("../view/auth/ForgotPassword"));
const ResetPassword = async(() => import("../view/auth/ResetPassword"));
const VerifyEmail = async(() => import("../view/auth/VerifyEmail"));
const Page404 = async(() => import("../view/auth/Page404"));
const PermissionDeniedPage = async(() => import("../view/auth/PermissionDeniedPage"));
const Page500 = async(() => import("../view/pages/Page500"));
const Pricing = async(() => import("../view/pricing/index"));

// EventsList routes
const MyEvents = async(() => import("../view/events/events-list/EventsList"));
const EventIndexPageEditor = async(() => import("../view/events/events-list/EventIndexPageEditor"));
const EventIndexPage = async(() => import("../view/events/event/EventIndexPage"));
const FormBuilderAGM = async(() => import("../view/form/form_builder/FormBuilderAGM"));
const FormBuilderWebcast = async(() => import("../view/form/form_builder/FormBuilderWebcast"));
const EventViewFormData = async(() => import("../view/attendees/index"));
const EventEditFormData = async(() => import("../view/attendees/Edit"));
const AddEventAGM = async(() => import("../view/events/add-event/AddEventAGM"));
const AddEventWebcast = async(() => import("../view/events/add-event/AddEventWebcast"));
const EventPage = async(() => import("../view/events/event/Event"));
const EventEmbedPage = async(() => import("../view/events/event/EventEmbed"));
const RegistrationForm = async(() => import("../view/form/form-view/views/RegistrationForm"))
const LoginForm = async(() => import("../view/form/form-view/views/LoginForm"))
const RegistrationFormPreview = async(() => import("../view/form/form-view/views/RegistrationFormPreview"))
const EventForgotPassword = async(() => import("../view/form/form-view/views/ForgotPassword"))
const VoteFormBuilder = async(() => import("../view/votes/create-vote"));
const Votes = async(() => import("../view/votes/votes-list"));
const CharimanVotePage = async(() => import("../view/votes/chairman-vote-page"));
const DisplayResultsPage = async(() => import("../view/votes/votes-list/DisplayResultsPage"))
const ScrutineerPage = async(() => import("../view/votes/votes-list/ScrutineerPage"))
const QuestionAndAnswers = async(() => import("../view/question-and-answer/Dashboard"));
const LiveStream = async(() => import("../view/live-stream"));
const ProjectorPage = async(() => import("../view/question-and-answer/ProjectorPagePublic"));
const ProjectorPagePlusOne = async(() => import("../view/question-and-answer/ProjectorPageCurrentNext"));
const EventAnalytics = async(() => import("../view/Analytics"));
// const Login = async(() => import("../view/events/login/Login"));
const Collaborator = async(() => import("../view/collaborator"));
const AddCollaborator = async(() => import("../view/collaborator/AddCollaborator"));
const Organisers = async(() => import("../view/admin/organisers"));
const AddOrganiser = async(() => import("../view/admin/organisers/AddOrganiser"));
const EmailTemplates = async(() => import("../view/admin/email-templates/index"));
const AddEmailTemplate = async(() => import("../view/admin/email-templates/AddEmailTemplate"));
const Subscriptions = async(() => import("../view/admin/subscription/index"));
const AddSubscription = async(() => import("../view/admin/subscription/AddSubscription"));
const VotingGroups = async(() => import("../view/voting-groups/index"));
const AddGroup = async(() => import("../view/voting-groups/AddGroup"));
const Profile = async(() => import("../view/user/Profile"));
const ChangePassword = async(() => import("../view/user/ChangePassword"));
const ChairmanPage = async(() => import("../view/question-and-answer/ChairmanPage"));
const WebStream = async(() => import("../view/live-stream/WebStream"));
const VideoLibrary = async(() => import("../view/video-library"));
const PublicLoginForm = async(() => import("../view/form/form-view/views/Public"))
const SingleSharedPasswordLoginForm = async(() => import("../view/form/form-view/views/SingleSharesPassword"))
const UniqueLoginForm = async(() => import("../view/form/form-view/views/UniqueLogin"))
const AttendeesUniqueLogin = async(() => import("../view/attendees/AttendeesUniqueLogin"))
const AddAttendeesUniqueLogin = async(() => import("../view/attendees/AddAttendeesUniqueLogin"))

const AttendeesUniqueLoginRoute = {
  path: "/:event_id/attendees",
  name: "Attendees",
  component: AttendeesUniqueLogin,
  guard: AuthGuard,
}
const AddAttendeesUniqueLoginRoute = {
  path: "/:event_id/attendees/add",
  name: "Add Attendees",
  component: AddAttendeesUniqueLogin,
  guard: AuthGuard,
}
const chairmanPageRoutes = {
  path: "/question-and-answer/chairman/:event_id/:event_url",
  name: "Chairman Page",
  component: ChairmanPage,
  guard: AuthGuard,
}
const changePasswordRoutes = {
  path: "/change_password",
  name: "Change password",
  component: ChangePassword,
  guard: AuthGuard,
}
const profileRoutes = {
  path: "/profile",
  name: "Profile",
  component: Profile,
  guard: AuthGuard,
}
const groupsRoutes = {
  id: "Voting Groups",
  path: "/voting-groups",
  icon: <Layers />,
  component: VotingGroups,
  guard: AuthGuard,
}
const addGroupRoutes = {
  path: "/add_group",
  name: "Add Group",
  component: AddGroup,
  guard: AuthGuard,
}
const editGroupRoutes = {
  path: "/edit_group/:id",
  name: "Edit Group",
  component: AddGroup,
  guard: AuthGuard,
}
const videoLibraryRoutes = {
  id: "Video Library",
  path: "/video-library",
  icon: <Youtube />,
  component: VideoLibrary,
  guard: AuthGuard,
}

const landingRoutes = {
  id: "Events",
  path: "/",
  icon: <Sunrise />,
  component: MyEvents,
  guard: AuthGuard,
}
const eventsRoutes = {
  id: "Events",
  path: "/events",
  icon: <Sunrise />,
  component: MyEvents,
  guard: AuthGuard,
}
const collaboratorRoutes = {
  id: "Collaborator",
  path: "/collaborator",
  icon: <Users />,
  component: Collaborator,
  guard: AuthGuard,
}
const addCollaboratorRoutes = {
  path: "/add_collaborator",
  name: "Add Collaborator",
  component: AddCollaborator,
  guard: AuthGuard,
}
const editCollaboratorRoutes = {
  path: "/edit_collaborator/:id",
  name: "Edit Collaborator",
  component: AddCollaborator,
  guard: AuthGuard,
}
const eventIndexPageEditorRoutes = {
  path: "/:event_url/index_page_editor",
  name: "Index Page",
  component: EventIndexPageEditor,
  guard: AuthGuard,
}
const eventIndexPageRoutes = {
  path: "/:event_url/index",
  name: "Index Page",
  component: EventIndexPage,
}
const FormBuilderAGMRoute = {
  path: "/:event_url/agm/create_form/:event_id/:type",
  name: "Form Builder AGM",
  component: FormBuilderAGM,
  guard: AuthGuard,
}
const FormBuilderAGMEditRoute = {
  path: "/:event_url/agm/edit_form/:id/:type",
  name: "EditEvent",
  component: FormBuilderAGM,
  guard: AuthGuard,
}
const FormBuilderWebcastRoute = {
  path: "/:event_url/webcast/create_form/:event_id/:type",
  name: "Form Builder AGM",
  component: FormBuilderWebcast,
  guard: AuthGuard,
}
const FormBuilderWebcastEditRoute = {
  path: "/:event_url/webcast/edit_form/:id/:type",
  name: "EditEvent",
  component: FormBuilderWebcast,
  guard: AuthGuard,
}
const EventViewFormDataRoute = {
  path: "/:event_url/view_form_data/:id",
  name: "EventViewFormData",
  component: EventViewFormData,
  guard: AuthGuard,
}
const EventEditFormDataRoute = {
  path: "/:event_url/edit_form_data/:id/:form_data_id",
  name: "EventEditFormData",
  component: EventEditFormData,
  guard: AuthGuard,
}
const AddEventAGMRoute = {
  path: "/event/agm/add/:type?/:event_url?",
  name: "AddEventAGM",
  component: AddEventAGM,
  guard: AuthGuard,
}
const EditEventAGMRoute = {
  path: "/event/agm/edit/:id",
  name: "Edit",
  component: AddEventAGM,
  guard: AuthGuard,
}
const AddEventWebcastRoute = {
  path: "/event/webcast/add/:type?/:event_url?",
  name: "AddEventWebcast",
  component: AddEventWebcast,
  guard: AuthGuard,
}
const EditEventWebcastRoute = {
  path: "/event/webcast/edit/:id",
  name: "Edit",
  component: AddEventWebcast,
  guard: AuthGuard,
}
const EventPageRoute = {
  path: "/:event_url",
  name: "Event",
  component: EventPage,
}
const EventEmbedPageRoute = {
  path: "/embed/:event_url",
  name: "EventEmbed",
  component: EventEmbedPage,
}

// MAIN EVENT ROUTES --START
// const LoginRoute = {
//   path: "/:event_url/login",
//   name: "Login",
//   component: Login,
// }
const RegistrationFormRoute = {
  path: "/:event_url/pre_registration_form",
  name: "Registration Form",
  component: RegistrationForm,
}
const LoginFormRoute = {
  path: "/:event_url/login",
  name: "Login Form",
  component: LoginForm,
}
const PublicLoginFormRoute = {
  path: "/:event_url/Public",
  name: "Login Form",
  component: PublicLoginForm,
}
const SingleSharedPasswordLoginFormRoute = {
  path: "/:event_url/SingleSharedPasswordLogin",
  name: "Login Form",
  component: SingleSharedPasswordLoginForm,
}
const UniqueLoginRoute = {
  path: "/:event_url/UniqueLogin",
  name: "Login Form",
  component: UniqueLoginForm,
}
const RegistrationFormPreviewRoute = {
  path: "/:event_url/preview",
  name: "Registration Form Preview",
  component: RegistrationFormPreview,
}
const EventForgotPasswordRoute = {
  path: "/:event_url/forgot_password",
  name: "Forgot Password",
  component: EventForgotPassword,
}
// MAIN EVENT ROUTES --END

// VOTES ROUTES --START
const votesRoute = {
  path: "/:event_url/votes/:event_id",
  name: "Votes",
  component: Votes,
  guard: AuthGuard,
}
const createVoteRoute = {
  path: "/:event_url/vote/create/:event_id",
  name: "Vote Form Builder",
  component: VoteFormBuilder,
  guard: AuthGuard,
}
const editVoteRoute = {
  path: "/:event_url/vote/edit/:event_id/:id",
  name: "Vote Form Builder",
  component: VoteFormBuilder,
  guard: AuthGuard,
}
const charimanVotePageRoute = {
  path: "/:event_url/votes",
  name: "Vote",
  component: CharimanVotePage,
  guard: AuthGuard,
}
// RESULTS PAGE
const displayResultsPageRoute = {
  path: "/results-page/:event_id/:event_url",
  name: "Results Page",
  component: DisplayResultsPage,
}
const scrutineerPageRoute = {
  path: "/scrutineer-page/:event_id",
  name: "Results Page",
  component: ScrutineerPage,
}
// VOTES ROUTES --END

// QUESTION AND ANSWER ROUTES --START
const questionAndAnswersRoute = {
  path: "/question-and-answer/:event_id/:event_url",
  name: "QuestionAndAnswers",
  component: QuestionAndAnswers,
  guard: AuthGuard,
}
// QUESTION AND ANSWER ROUTES --END

// LIVE STREAM --START
const liveStreamRoute = {
  path: "/live-stream/:event_id/:event_url",
  name: "Live Stream",
  component: LiveStream,
  guard: AuthGuard,
}
const WebStreamRoute = {
  path: "/web-stream/:event_id/:stream_key",
  name: "Web Stream",
  component: WebStream,
  guard: AuthGuard,
}
// LIVE STREAM --END

// PROJECTOR PAGE
const projectorPageRoute = {
  path: "/projector-page/:event_id/:event_url",
  name: "Projector Page",
  component: ProjectorPage,
}
const projectorPagePlusOneRoute = {
  path: "/projector-page/:type/:event_id/:event_url",
  name: "Projector Page",
  component: ProjectorPagePlusOne,
}

const EventAnalyticsRoute = {
  path: "/development/:event_url/analytics",
  name: "Analytics",
  component: EventAnalytics,
  guard: AuthGuard,
}

// ADMIN ROUTES
const organisersRoutes = {
  id: "Organisers",
  path: "/organisers",
  icon: <Users />,
  component: Organisers,
  guard: AuthGuard,
}
const addOrganiserRoutes = {
  path: "/add_organiser",
  name: "Add Organiser",
  component: AddOrganiser,
  guard: AuthGuard,
}
const EmailTemplatesRoutes = {
  id: "Email Templates",
  path: "/email_templates",
  icon: <Mail />,
  component: EmailTemplates,
  guard: AuthGuard,
}
const addEmailTemplateRoutes = {
  path: "/add_email_template",
  name: "Add Email Template",
  component: AddEmailTemplate,
  guard: AuthGuard,
}
const editEmailTemplateRoutes = {
  path: "/edit_email_template/:id",
  name: "edit Email Template",
  component: AddEmailTemplate,
  guard: AuthGuard,
}

const SubscriptionsRoutes = {
  id: "Subscriptions",
  path: "/subscriptions",
  icon: <DollarSign />,
  component: Subscriptions,
  guard: AuthGuard,
}
const addSubscriptionRoutes = {
  path: "/add_subscription",
  name: "Add Subscription",
  component: AddSubscription,
  guard: AuthGuard,
}
const editSubscriptionRoutes = {
  path: "/edit_subscription/:id",
  name: "edit Subscription",
  component: AddSubscription,
  guard: AuthGuard,
}
const pricingRoutes = {
  id: "Pricing",
  path: "/pricing",
  component: Pricing,
}

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up/:token?",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/forgot-password",
      name: "Forgot Password",
      component: ForgotPassword,
    },
    {
      path: "/auth/reset-password/:token",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/verify-email/:token",
      name: "Verify Email",
      component: VerifyEmail,
    },
    {
      path: "/page-not-found/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/permission/denied",
      name: "Permission Denied Page",
      component: PermissionDeniedPage,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  landingRoutes,
  eventsRoutes,
  collaboratorRoutes,
  addCollaboratorRoutes,
  editCollaboratorRoutes,
  eventIndexPageEditorRoutes,
  votesRoute,
  createVoteRoute,
  editVoteRoute,
  charimanVotePageRoute,
  questionAndAnswersRoute,
  liveStreamRoute,
  AddEventAGMRoute,
  EditEventAGMRoute,
  AddEventWebcastRoute,
  EditEventWebcastRoute,
  FormBuilderAGMRoute,
  FormBuilderAGMEditRoute,
  FormBuilderWebcastRoute,
  FormBuilderWebcastEditRoute,
  EventViewFormDataRoute,
  EventEditFormDataRoute,
  EventAnalyticsRoute,
  groupsRoutes,
  addGroupRoutes,
  editGroupRoutes,
  profileRoutes,
  changePasswordRoutes,
  videoLibraryRoutes,
  pricingRoutes,
  AttendeesUniqueLoginRoute,
  AddAttendeesUniqueLoginRoute
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [
  EventPageRoute,
  EventEmbedPageRoute,
  eventIndexPageRoutes,
  // LoginRoute,
  RegistrationFormRoute,
  LoginFormRoute,
  PublicLoginFormRoute,
  SingleSharedPasswordLoginFormRoute,
  UniqueLoginRoute,
  RegistrationFormPreviewRoute,
  EventForgotPasswordRoute,
  projectorPageRoute,
  projectorPagePlusOneRoute,
  displayResultsPageRoute,
  chairmanPageRoutes,
  WebStreamRoute,
  scrutineerPageRoute,
];

// Routes that are protected
export const protectedRoutes = [
  eventsRoutes,
];

// Routes visible in the sidebar
export const sidebarRoutes = [
  eventsRoutes,
  collaboratorRoutes,
  groupsRoutes,
  videoLibraryRoutes
];

export const adminRoutes = [
  organisersRoutes,
  addOrganiserRoutes,
  EmailTemplatesRoutes,
  SubscriptionsRoutes,
  addSubscriptionRoutes,
  editSubscriptionRoutes,
  addEmailTemplateRoutes,
  editEmailTemplateRoutes
];

// Routes visible in the sidebar
export const adminSidebarRoutes = [
  organisersRoutes,
  addEmailTemplateRoutes,
  EmailTemplatesRoutes,
  SubscriptionsRoutes,
  addSubscriptionRoutes,
  editSubscriptionRoutes,
  addEmailTemplateRoutes,
  editEmailTemplateRoutes
];

export const collaboratorLayoutRoutes = [
  eventsRoutes
];

// Routes visible in the sidebar
export const collaboratorSidebarRoutes = [
  eventsRoutes,
];