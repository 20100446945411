import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.GET_ALL_SUBSCRIPTION: {
      return {
        ...state,
        subscriptions: actions.subscriptions
      };
    }
    case types.CREATE_SUBSCRIPTION: {
      return {
        ...state,
        subscription: actions.subscription
      };
    }
    case types.UPDATE_SUBSCRIPTIONS: {
      return {
        ...state,
        subscription: actions.subscription
      };
    }
    default: {
      return {
        ...state,
        subscriptions: [],
      };
    }
  }
}