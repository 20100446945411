/* eslint-disable prettier/prettier */
import * as types from "../../constants";
import {
  signIn as authSignIn,
  signUp as authSignUp,
  resetPassword as authResetPassword,
  confirmResetPassword as authConfirmResetPassword,
  verifyEmail,
  getAllRoles,
  updateUser,
  getAllUserEmails,
  addCollaborator,
  updateCollaborator,
  getCollaborators,
  inviteCollaborator,
  updateCollaboratorInvitation,
  deleteCollaborators,
  updateUserProfile,
  updatePassword
} from "../../services/authService";
import {
  updateEventAccess,
  getUser,
  getById
} from "../../services/eventService";
import { getEmailTemplatesByEvent, sendEmail } from "../../services/emailService"

export function signIn(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });

    return authSignIn(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_IN_SUCCESS,
          token: response.accessToken,
          user_details: response.user_details,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
        throw error;
      });
  };
}

export function signUp(data) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_UP_REQUEST });

    return authSignUp(data)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_UP_SUCCESS,
        });
        // if (data.eventAccess) {
        //   data.eventAccess.map(async item => {
        //     await updateEventAccess(item.eventId, { event_access: response._id })
        //   })
        // }
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_UP_FAILURE });
        throw error;
      });
  };
}

export function updateCollaboratorInvitationAction(data, user) {
  return async (dispatch) => {
    console.log('user', user)
    // dispatch({ type: types.AUTH_SIGN_UP_REQUEST });
    return updateCollaboratorInvitation(data)
      .then(async (response) => {
        const emailTemplates = await getEmailTemplatesByEvent(user.eventAccess[0].eventId);
        const emailTemplate = await emailTemplates.emailTemplates.filter(item => item.label === 'Welcome email for collaborator')
        const sendData = {
          "templateID": emailTemplate[0]._id,
          "event_id": user.eventAccess[0].eventId,
          "fromName": "",
          "receiverInfo": [
            {
              "email": user.email,
              "registrant_name": `${data.first_name} ${data.last_name}`,
              "event_name": user.eventAccess[0].eventName,
              "company_name": user.company
            }
          ]
        }
        await sendEmail(sendData)

        dispatch({
          type: types.AUTH_UPDATE_SIGN_UP_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_UP_FAILURE });
        throw error;
      });
  };
}

export const deleteCollaboratorAction = (id) => async (dispatch, getState) => {
  const { authReducer } = getState()
  return deleteCollaborators(id)
    .then(async () => {
      const collaborators = [...authReducer.collaborators]
      const index = await collaborators.findIndex(collaborator => collaborator._id === id)
      const userId = collaborators[index].user_id._id
      const userEventAccess = [...collaborators[index].user_id.eventAccess]
      collaborators[index].eventAccess.map(async item => {
        // Remove from events table
        const getEventResponse = await getById(item.eventId)
        const eventEventAccess = [...getEventResponse.event.event_access]
        const eventEventAccessIndex = eventEventAccess.findIndex(res => res.user_id === userId)
        eventEventAccess.splice(eventEventAccessIndex, 1)
        await updateEventAccess(item.eventId, { event_access: eventEventAccess }, true)
        // Remove event access From User table
        const userEventAccessIndex = userEventAccess.findIndex(res => res.eventId === item.eventId)
        userEventAccess.splice(userEventAccessIndex, 1)
        await updateUser(userId, { eventAccess: userEventAccess })
      })
      // Removing collaborator table
      collaborators.splice(index, 1)
      dispatch({
        type: types.GET_COLLABORATOR_SUCCESS,
        collaborators
      });
    })
    .catch((error) => {
      dispatch({ type: types.GET_USER_FAILURE });
      throw error;
    });
};

export function signOut() {
  return async (dispatch) => {
    dispatch({
      type: types.AUTH_SIGN_OUT,
    });
  };
}

export function verifyEmailAction(data) {
  return async (dispatch) => {
    dispatch({ type: types.VERIFY_EMAIL_REQUEST });

    return verifyEmail(data)
      .then((response) => {
        dispatch({
          type: types.VERIFY_EMAIL_SUCCESS,
          token: response.accessToken,
          user_details: response.user_details,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.VERIFY_EMAIL_FAILURE,
          errorMsg: error
        });
        throw error;
      });
  };
}

export function resetPassword(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });

    return authResetPassword(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
          email: response.email,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw error;
      });
  };
}

export function confirmResetPasswordAction(data) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });
    return authConfirmResetPassword(data)
      .then((response) => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
          email: response.email,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw error;
      });
  };
}

export function getAllRolesAction() {
  return async (dispatch) => {
    return getAllRoles()
      .then((response) => {
        dispatch({
          type: types.GET_ALL_ROLES_SUCCESS,
          roles: response.roles,
        });
      })
      .catch((error) => {
        dispatch({ type: types.GET_ALL_ROLES_FAILURE });
        throw error;
      });
  };
}

export function inviteCollaboratorAction(data) {
  return async (dispatch, getState) => {
    const { authReducer } = getState()
    return inviteCollaborator(data)
      .then(async (response) => {
        // if (data.eventAccess) {
        //   data.eventAccess.map(async item => {
        //     await updateEventAccess(item.eventId, {
        //       event_access: {
        //         user_id: response.user._id,
        //         permission: item.role
        //       }
        //     })
        //   })
        // }
        const addCollaboratorResponse = await addCollaborator(
          { creator: authReducer.user.user_details.id, user_id: response.user._id, ...data },
          authReducer.user.user_details.email,
          false
        )

        const eventAccess = addCollaboratorResponse.collaborators.eventAccess.filter(item => item.eventId === data.eventAccess[0].eventId)
        await updateEventAccess(eventAccess[0].eventId, {
          event_access: {
            user_id: response.user._id,
            permission: eventAccess[0].role
          }
        })
        
        dispatch({
          type: types.AUTH_SIGN_UP_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_UP_FAILURE });
        throw error;
      });
  };
}

export function updateUserAction(id, data, actionType, collaboratotId) {
  return async (dispatch, getState) => {
    const { authReducer } = getState()
    return updateUser(id, data)
      .then(async (response) => {
        let addCollaboratorResponse
        if (actionType === 'add') {
          const payload = {
            creator: authReducer.user.user_details.id,
            user_id: id,
            ...data,
            invitationStatus: true
          }
          addCollaboratorResponse = await addCollaborator(
            payload,
            authReducer.user.user_details.email,
            true
          )
        }
        if (actionType === 'update') {
          addCollaboratorResponse = await updateCollaborator(
            collaboratotId,
            { user_id: id, ...data },
            authReducer.user.user_details.email
          )
        }

        const eventAccess = addCollaboratorResponse.collaborators.eventAccess.filter(item => item.eventId === data.eventAccess[0].eventId)
        await updateEventAccess(eventAccess[0].eventId, {
          event_access: {
            user_id: response.user._id,
            permission: eventAccess[0].role
          }
        })

        dispatch({
          type: types.AUTH_SIGN_UP_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_UP_FAILURE });
        throw error;
      });
  };
}

export function updateProfileAction(data) {
  return async (dispatch, getState) => {
    const { authReducer } = getState()
    return updateUserProfile(data, authReducer.user.token)
      .then(async (response) => {
        dispatch({
          type: types.AUTH_SIGN_IN_SUCCESS,
          token: response.accessToken,
          user_details: response.user_details,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_UP_FAILURE });
        throw error;
      });
  };
}

export function updatePasswordAction(data) {
  return async (dispatch, getState) => {
    const { authReducer } = getState()
    return updatePassword(data, authReducer.user.token)
      .then(async (response) => {
        dispatch({
          type: types.AUTH_SIGN_IN_SUCCESS,
          token: response.accessToken,
          user_details: response.user_details,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_UP_FAILURE });
        throw error;
      });
  };
}

export const getAttendeeAction = (id) => async (dispatch, getState) => {
  return getUser(id)
    .then((response) => {
      dispatch({
        type: types.GET_USER_SUCCESS,
        user_details: response.user,
      });
    })
    .catch((error) => {
      dispatch({ type: types.GET_USER_FAILURE });
      throw error;
    });
};

export const getCollaboratorsAction = () => async (dispatch, getState) => {
  const { authReducer } = getState()
  return getCollaborators(authReducer.user.user_details.id)
    .then((response) => {
      dispatch({
        type: types.GET_COLLABORATOR_SUCCESS,
        collaborators: response.collaborators,
      });
    })
    .catch((error) => {
      dispatch({ type: types.GET_USER_FAILURE });
      throw error;
    });
};

export const getAllUserEmailsAction = () => async (dispatch, getState) => {
  const { authReducer } = getState()
  return getAllUserEmails(authReducer.user.user_details.company, authReducer.user.user_details.email)
    .then((response) => {
      dispatch({
        type: types.GET_ALL_EMAILS_SUCCESS,
        emails: response.users
      });
    })
    .catch((error) => {
      dispatch({ type: types.GET_ALL_ROLES_FAILURE });
      throw error;
    });
};