import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.GET_ALL_ORGANISERS: {
      return {
        ...state,
        organisers: actions.organisers
      };
    }
    case types.GET_ALL_SUBSCRIPTIONS: {
      return {
        ...state,
        subscriptions: actions.subscriptions
      };
    }
    case types.UPDATE_SUBSCRIPTION: {
      return {
        ...state,
        organisers: actions.organisers
      };
    }
    case types.DELETE_ORGANISERS: {
      return {
        ...state,
        organisers: actions.organisers
      };
    }
    case types.GET_USER_SUCCESS: {
      return {
        ...state,
        user: actions.user
      };
    }
    default: {
      return {
        ...state,
        organisers: [],
        subscriptions: [],
        user: ''
      };
    }
  }
}