//import axios from "../utils/axios";
import axios from "axios";

export function signIn(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_LIVESTREAM_API}/api/auth/`, credentials, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response", response);

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("errior", error);
        reject(error);
      });
  });
}

export function signUp(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/`, credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resendVerificationEmail(email) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/resend/email`, {email})
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateUser(id, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/${id}`, data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateUserProfile(data, token) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/profile/update`, data, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Berar ${token}`
        }
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updatePassword(data, token) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/password/update`, data, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Berar ${token}`
        }
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getUser(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/${id}`)
      .then((response) => {
        if (response.status === 200 ) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteUser(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/${id}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function verifyEmail(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_LIVESTREAM_API}/api/auth/verify-email`, data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resetPassword(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_LIVESTREAM_API}/api/auth/reset-password`, credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function confirmResetPassword(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_LIVESTREAM_API}/api/auth/confirm-reset-password`, data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const getAllRoles = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_LIVESTREAM_API}/api/auth/roles`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }

      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  })
}

export function checkAvailability(key, value) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/check/availability?${key}=${value}`)
      .then((response) => {
        if (response.status === 200 && response.data.success === true) {
          return resolve(true)
        }
        resolve(false)
      })
      .catch((error) => {
        resolve(false);
      });
  });
}

export function checkEmailAvailability(key, value) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/check/availability?${key}=${value}`)
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        resolve(false)
      })
      .catch((error) => {
        resolve(false);
      });
  });
}

export function getAllUserEmails(companyName, loggedInUser) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/get/emails/${companyName}/${loggedInUser}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addCollaborator(data, email, sendmail) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/collaborator/${email}/${sendmail}`, data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function inviteCollaborator(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/invite/collaborator`, data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateCollaboratorInvitation(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/update/collaborator/invitation`, data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateCollaborator(id, data, email) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/collaborator/update/${id}/${email}`, data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const getCollaborators = (user_id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/collaborator/${user_id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }

      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  })
}

export function deleteCollaborators(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/collaborator/delete/${id}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getUserByToken(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/get/userbytoken`, data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}