import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  dashboardLayoutRoutes,
  authLayoutRoutes,
  presentationLayoutRoutes,
  protectedRoutes,
  adminRoutes,
} from "./index";
import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import PresentationLayout from "../layouts/Presentation";
import Page404 from "../view/pages/Page404";

const childRoutes = (Layout, routes) =>
  routes.map(({ component: Component, guard, children, path }, index) => {
    const Guard = guard || React.Fragment;

    return children ? (
      children.map((element, index) => {
        const Guard = element.guard || React.Fragment;

        return (
          <Route
            key={index}
            path={element.path}
            exact
            render={(props) => (
              <Guard>
                <Layout>
                  <element.component {...props} />
                </Layout>
              </Guard>
            )}
          />
        );
      })
    ) : Component ? (
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Guard>
            <Layout>
              <Component {...props} />
            </Layout>
          </Guard>
        )}
      />
    ) : null;
  });

const Routes = () => {
  const [isAdmin, setIsAdmin] = useState(false)
  // const [isStaff, setIsStaff] = useState(false)

  const auth = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (auth?.user?.user_details?.role[0]?.name === 'admin') return setIsAdmin(true)

    // if (auth?.user?.user_details?.role.length > 0) {
    //   const rolesArray = auth?.user?.user_details?.role
    //   rolesArray.map(role => {
    //     if(role.name !== 'organiser') return setIsStaff(true)
    //   })
    // }

  }, [auth])

  return (
    <Router>
      <Switch>
        {/* {isStaff && childRoutes(DashboardLayout, collaboratorLayoutRoutes)} */}
        {isAdmin && childRoutes(DashboardLayout, adminRoutes)}
        {childRoutes(DashboardLayout, dashboardLayoutRoutes)}
        {childRoutes(DashboardLayout, protectedRoutes)}
        {childRoutes(PresentationLayout, presentationLayoutRoutes)}
        {childRoutes(AuthLayout, authLayoutRoutes)}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </Router>
  )
};

export default Routes;
