import * as types from "../../constants";

const initialState = {
  playlist: [],
  videos: [],
  events: [],
  videosTotal: ''
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SAVE_PLAYLIST_SUCCESS: {
      return {
        ...state
      };
    }
    case types.GET_ALL_PLAYLIST_SUCCESS: {
      return {
        ...state,
        playlist: actions.playlist
      };
    }
    case types.DELETE_PLAYLIST_SUCCESS: {
      return {
        ...state,
        playlist: actions.playlist
      };
    }
    case types.UPDATE_PLAYLIST_SUCCESS: {
      return {
        ...state,
        playlist: actions.playlist
      };
    }
    case types.VIDEO_ADDED_TO_PLAYLIST_SUCCESS: {
      return {
        ...state
      };
    }
    case types.GET_ALL_VIDEOS_SUCCESS: {
      return {
        ...state,
        videos: actions.videos,
        videosTotal: actions.videosTotal
      };
    }
    case types.GET_ALL_PLAYLIST_VIDEOS_SUCCESS: {
      return {
        ...state,
        videos: actions.videos
      };
    }
    case types.UPDATE_VIDEO_SUCCESS: {
      return {
        ...state,
        videos: actions.videos
      };
    }
    case types.VIDEO_REMOVED_FROM_PLAYLIST_SUCCESS: {
      return {
        ...state,
        videos: actions.videos
      };
    }
    case types.DELETE_VIDEO_SUCCESS: {
      return {
        ...state,
        videos: actions.videos
      };
    }
    case types.GET_ALL_EVENTS_SUCCESS: {
      return {
        ...state,
        events: actions.events
      };
    }
    default: {
      return state
    }
  }
}