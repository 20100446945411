import * as types from "../../constants";

const initialState = {
  attendees: [],
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.GET_ALL_ATTENDEES: {
      return {
        ...state,
        attendees: actions.attendees
      };
    }
    case types.DELETE_ATTENDEE: {
      return {
        ...state,
        attendees: actions.attendees
      };
    }
    default: {
      return state
    }
  }
}
