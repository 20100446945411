// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export const SET_ROOM_ID = "SET_ROOM_ID"
export const SET_PRIVATE_CHAT_STATUS = "SET_PRIVATE_CHAT_STATUS"
// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_UPDATE_SIGN_UP_SUCCESS = "AUTH_UPDATE_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";
export const GENERATE_API_KEY_SUCCESS = "GENERATE_API_KEY_SUCCESS";
export const GENERATE_API_KEY_FAILURE = "GENERATE_API_KEY_FAILURE"
export const VERIFY_EMAIL_REQUEST = "VERIFY_EMAIL_REQUEST"
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS"
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE"
export const GET_ALL_ROLES_SUCCESS = "GET_ALL_ROLES_SUCCESS"
export const GET_ALL_ROLES_FAILURE = "GET_ALL_ROLES_FAILURE"
export const GET_ALL_EMAILS_SUCCESS = "GET_ALL_EMAILS_SUCCESS"
export const GET_COLLABORATOR_SUCCESS = "GET_COLLABORATOR_SUCCESS"

// CHAT
export const GET_ALL_CHATS_REQUEST = "GET_ALL_CHATS_REQUEST";
export const GET_ALL_CHATS_SUCCESS = "GET_ALL_CHATS_SUCCESS";
export const GET_ALL_CHATS_FAILURE = "GET_ALL_CHATS_FAILURE";
export const SHOW_REPLY_INPUT = "SHOW_REPLY_INPUT"
export const DELETE_CHAT = "DELETE_CHAT"
export const ENABLE_DELETE_CHAT = "ENABLE_DELETE_CHAT"

// PRIVATE CHAT
export const SHOW_PRIVATE_REPLY_INPUT = "SHOW_PRIVATE_REPLY_INPUT"
export const DELETE_PRIVATE_CHAT = "DELETE_PRIVATE_CHAT"
export const ENABLE_PRIVATE_DELETE_CHAT = "ENABLE_PRIVATE_DELETE_CHAT"

// CHAT USER
export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";

// FORMS
export const GET_ALL_FORMSDATA_SUCCESS = "GET_ALL_FORMSDATA_SUCCESS"
export const OPEN_FORMS_DIALOG = "OPEN_FORMS_DIALOG"
export const ENABLE_EDIT_SUCCESS = "ENABLE_EDIT_SUCCESS"
export const ENABLE_FORM_EDIT_SUCCESS = "ENABLE_FORM_EDIT_SUCCESS"
export const DELETE_FIELD_SUCCESS = "DELETE_FIELD_SUCCESS"
export const EMPTY_FORMDATA_SUCCESS = "EMPTY_FORMDATA_SUCCESS"
export const CREATE_FORM_SUCCESS = "CREATE_FORM_SUCCESS"
export const SAVE_FORM_SUCCESS = "SAVE_FORM_SUCCESS"
export const SAVE_FORM_FAILURE = "SAVE_FORM_FAILURE"
export const TITLE_SUCCESS = "TITLE_SUCCESS"
export const DESCRIPTION_SUCCESS = "DESCRIPTION_SUCCESS"
export const GET_ALL_FORMS_SUCCESS = "GET_ALL_FORMS_SUCCESS"
export const GET_ALL_VIEWFORMSDATA_SUCCESS = "GET_ALL_VIEWFORMSDATA_SUCCESS"
export const UPDATE_FORM_DATA = "UPDATE_FORM_DATA"
export const REGISTRATION_CLOSE_DATE = "REGISTRATION_CLOSE_DATE"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"
export const SET_ERROR_SUCCESS = "SET_ERROR_SUCCESS"
export const CREDITS_TITLE_SUCCESS = "CREDITS_TITLE_SUCCESS"
export const CREDITS_INFORMATION_SUCCESS = "CREDITS_INFORMATION_SUCCESS"
export const STORE_MESSAGE_TEMPLATE_SUCCESS = "STORE_MESSAGE_TEMPLATE_SUCCESS"
export const GET_ALL_FILTERED_SUCCESS = "GET_ALL_FILTERED_SUCCESS"

// EVENTS
export const GET_ALL_EVENTS_SUCCESS = "GET_ALL_EVENTS_SUCCESS"
export const GET_ALL_EVENTS_FAILURE = "GET_ALL_EVENTS_FAILURE"
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS"
export const CREATE_EVENT_FAILURE = "CREATE_EVENT_FAILURE"
export const UPDATE_EVENTS_VALUES_SUCCESS = "UPDATE_EVENTS_VALUES_SUCCESS"
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS"
export const DELETE_EVENT_FAILURE = "DELETE_EVENT_FAILURE"
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS"
export const GET_EVENT_FAILURE = "GET_EVENT_FAILURE"
export const IMAGE_UPLOADED_SUCCESS = "IMAGE_UPLOADED_SUCCESS"
export const IMAGE_UPLOADED_FAILURE = "IMAGE_UPLOADED_FAILURE"
export const DOCUMENT_UPLOADED_SUCCESS = "DOCUMENT_UPLOADED_SUCCESS"
export const DOCUMENT_UPLOADED_FAILURE = "DOCUMENT_UPLOADED_FAILURE"
export const ADD_COLOR_SUCCESS = "ADD_COLOR_SUCCESS"
export const STREAM_FORMAT_SUCCESS = "STREAM_FORMAT_SUCCESS"
export const STREAM_FORMAT_FAILURE = "STREAM_FORMAT_FAILURE"
export const ENCODING_TEMPLATE_SUCCESS = "ENCODING_TEMPLATE_SUCCESS"
export const ENCODING_TEMPLATE_FAILURE = "ENCODING_TEMPLATE_FAILURE"
export const RTMP_SUCCESS = "RTMP_SUCCESS"
export const RTMP_FAILURE = "RTMP_FAILURE"
export const DELETE_RTMP_SUCCESS = "DELETE_RTMP_SUCCESS"
export const DELETE_RTMP_FAILURE = "DELETE_RTMP_FAILURE"
export const WEBHOOK_SUCCESS = "WEBHOOK_SUCCESS"
export const WEBHOOK_FAILURE = "WEBHOOK_FAILURE"
export const UPDATE_FEATURE_STATUS_SUCCESS = "UPDATE_FEATURE_STATUS_SUCCESS"
export const UPDATE_VIDEO_PLAYER_OPTIONS_SUCCESS = "UPDATE_VIDEO_PLAYER_OPTIONS_SUCCESS"
export const EMBEDALT_UPDATED_SUCCESS = "EMBEDALT_UPDATED_SUCCESS"
export const EMBEDALT_UPDATED_FAILURE = "EMBEDALT_UPDATED_FAILURE"
export const ONGOING_VIDEO_SUCCESS = "ONGOING_VIDEO_SUCCESS"
export const ONGOING_VIDEO_FAILURE = "ONGOING_VIDEO_FAILURE"
export const UPDATE_VIDEO_SUCCESS = "UPDATE_VIDEO_SUCCESS"
export const UPDATE_VIDEO_FAILURE = "UPDATE_VIDEO_FAILURE"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAILURE = "GET_USER_FAILURE"
export const GET_ALL_STREAM_KEYS_SUCCESS = "GET_ALL_STREAM_KEYS_SUCCESS"
export const GET_ALL_STREAM_KEYS_FAILURE = "GET_ALL_STREAM_KEYS_FAILURE"
export const STEPPER = "STEPPER"
export const CREATE_CHAT_SUCCESS = "CREATE_CHAT_SUCCESS"
export const CREATE_CHAT_FAILURE = "CREATE_CHAT_FAILURE"
export const GET_CHATS_SUCCESS = "GET_CHATS_SUCCESS"
export const GET_CHATS_FAILURE = "GET_CHATS_FAILURE"
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS"
export const GET_ALL_NOTIFICATIONS_FAILURE = "GET_ALL_NOTIFICATIONS_FAILURE"
export const FIELDS_ORDER = "FIELDS_ORDER"
export const APPEND_QUESTION_ID_TO_EVENT_SUCCESS = "APPEND_QUESTION_ID_TO_EVENT_SUCCESS"

// VOTES
export const ADD_VOTE_FIELD_SUCCESS = "ADD_VOTE_FIELD_SUCCESS"
export const ADD_VOTE_FIELD_FAILURE = "ADD_VOTE_FIELD_FAILURE"
export const QUESTION_SUCCESS = "QUESTION_SUCCESS"
export const GET_ALL_VOTES_SUCCESS = "GET_ALL_VOTES_SUCCESS"
export const GET_ALL_VOTES_FAILURE = "GET_ALL_VOTES_FAILURE"
export const DELETE_VOTE_SUCCESS = "DELETE_VOTE_SUCCESS"
export const DELETE_VOTE_FAILURE = "DELETE_VOTE_FAILURE"
export const UPDATE_FORM_SUCCESS = "UPDATE_FORM_SUCCESS"
export const UPDATE_FORM_FAILURE = "UPDATE_FORM_FAILURE"
export const GET_VOTE_RESULT_SUCCESS = "GET_VOTE_RESULT_SUCCESS"
export const GET_VOTE_RESULT_FAILURE = "GET_VOTE_RESULT_FAILURE"
export const GET_LIVE_VOTING_SUCCESS = "GET_LIVE_VOTING_SUCCESS"

// QUESTION AND ANSWER
export const SAVE_TEXT_SUCCESS = "SAVE_TEXT_SUCCESS"
export const ADD_QNA_FIELD_FAILURE = "ADD_QNA_FIELD_FAILURE"
export const GET_ALL_QNAS_SUCCESS = "GET_ALL_QNAS_SUCCESS"
export const GET_ALL_QNAS_FAILURE = "GET_ALL_QNAS_FAILURE"
export const DELETE_QNA_SUCCESS = "DELETE_QNA_SUCCESS"
export const DELETE_QNA_FAILURE = "DELETE_QNA_FAILURE"
export const GET_QNA_RESULT_SUCCESS = "GET_QNA_RESULT_SUCCESS"
export const GET_QNA_RESULT_FAILURE = "GET_QNA_RESULT_FAILURE"
export const APPEND_QUESTION_TO_STATE_SUCCESS = "APPEND_QUESTION_TO_STATE_SUCCESS"
export const GET_ALL_QUESTIONS_SUCCESS = "GET_ALL_QUESTIONS_SUCCESS"
export const GET_ALL_APPROVED_SUCCESS = "GET_ALL_APPROVED_SUCCESS"
export const GET_ALL_ANSWERED_SUCCESS = "GET_ALL_ANSWERED_SUCCESS"
export const GET_ALL_DELETED_SUCCESS = "GET_ALL_DELETED_SUCCESS"
export const QNAS_CSV_DATA = "QNAS_CSV_DATA"
export const SAVE_QUESTION_ANSWER_SUCCESS = "SAVE_QUESTION_ANSWER_SUCCESS"
export const SAVE_APPROVED_ANSWER_SUCCESS = "SAVE_APPROVED_ANSWER_SUCCESS"
export const SAVE_ANSWER_FAILURE = "SAVE_ANSWER_FAILURE"
export const SAVE_QUESTION_TEXT_SUCCESS = "SAVE_QUESTION_TEXT_SUCCESS"
export const SAVE_APPROVED_TEXT_SUCCESS = "SAVE_APPROVED_TEXT_SUCCESS"

// VIDEO LIBRARY
export const SAVE_PLAYLIST_SUCCESS = "SAVE_PLAYLIST_SUCCESS"
export const SAVE_PLAYLIST_FAILURE = "SAVE_PLAYLIST_FAILURE"
export const UPDATE_PLAYLIST_SUCCESS = "UPDATE_PLAYLIST_SUCCESS"
export const UPDATE_PLAYLIST_FAILURE = "UPDATE_PLAYLIST_FAILURE"
export const GET_ALL_PLAYLIST_SUCCESS = "GET_ALL_PLAYLIST_SUCCESS"
export const GET_ALL_PLAYLIST_FAILURE = "GET_ALL_PLAYLIST_FAILURE"
export const DELETE_PLAYLIST_SUCCESS = "DELETE_PLAYLIST_SUCCESS"
export const DELETE_PLAYLIST_FAILURE = "DELETE_PLAYLIST_FAILURE"

export const SAVE_VIDEO_SUCCESS = "SAVE_VIDEO_SUCCESS"
export const SAVE_VIDEO_FAILURE = "SAVE_VIDEO_FAILURE"
export const GET_ALL_VIDEOS_SUCCESS = "GET_ALL_VIDEOS_SUCCESS"
export const GET_ALL_VIDEOS_FAILURE = "GET_ALL_VIDEOS_FAILURE"
export const DELETE_VIDEO_SUCCESS = "DELETE_VIDEO_SUCCESS"
export const DELETE_VIDEO_FAILURE = "DELETE_VIDEO_FAILURE"
export const GET_ALL_PLAYLIST_VIDEOS_SUCCESS = "GET_ALL_PLAYLIST_VIDEOS_SUCCESS"
export const GET_ALL_PLAYLIST_VIDEOS_FAILURE = "GET_ALL_PLAYLIST_VIDEOS_FAILURE"
export const VIDEO_ADDED_TO_PLAYLIST_SUCCESS = "VIDEO_ADDED_TO_PLAYLIST_SUCCESS"
export const VIDEO_ADDED_TO_PLAYLIST_FAILURE = "VIDEO_ADDED_TO_PLAYLIST_FAILURE"
export const VIDEO_REMOVED_FROM_PLAYLIST_SUCCESS = "VIDEO_REMOVED_FROM_PLAYLIST_SUCCESS"
export const VIDEO_REMOVED_FROM_PLAYLIST_FAILURE = "VIDEO_REMOVED_FROM_PLAYLIST_FAILURE"


export const GET_ALL_ORGANISERS = "GET_ALL_ORGANISERS"
export const GET_ALL_ORGANISERS_FAILURE = "GET_ALL_ORGANISERS_FAILURE"
export const GET_ALL_SUBSCRIPTIONS = "GET_ALL_SUBSCRIPTIONS"
export const GET_ALL_SUBSCRIPTIONS_FAILURE = "GET_ALL_SUBSCRIPTIONS_FAILURE"
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION"
export const UPDATE_SUBSCRIPTION_FAILURE = "UPDATE_SUBSCRIPTION_FAILURE"
export const DELETE_ORGANISERS = "DELETE_ORGANISERS"
export const DELETE_ORGANISERS_FAILURE = "DELETE_ORGANISERS_FAILURE"

// Graph Data
export const CREATE_GRAPH_DATA = "CREATE_GRAPH_DATA"
export const GET_GRAPH_DATA = "GET_GRAPH_DATA"
export const GET_SUMMARY_DATA = "GET_SUMMARY_DATA"
export const GET_GRAPH_DATA_FAILURE = "GET_GRAPH_DATA_FAILURE"
export const GET_CURRENT_ATTENDEES_DATA = "GET_CURRENT_ATTENDEES_DATA"
export const GET_CURRENT_ATTENDEES_FAILURE = "GET_CURRENT_ATTENDEES_FAILURE"
export const GET_NOT_SHOWEDUP_ATTENDEES_DATA = "GET_NOT_SHOWEDUP_ATTENDEES_DATA"
export const GET_NOT_SHOWEDUP_ATTENDEES_DATA_FAILURE = "GET_NOT_SHOWEDUP_ATTENDEES_DATA_FAILURE"

// Email
export const CREATE_EMAIL_VARIABLES_SUCCESS = "CREATE_EMAIL_VARIABLES_SUCCESS"
export const CREATE_EMAIL_VARIABLES_FAILURE = "CREATE_EMAIL_VARIABLES_FAILURE"
export const GET_ALL_EMAIL_VARIABLES_SUCCESS = "GET_ALL_EMAIL_VARIABLES_SUCCESS"
export const GET_ALL_EMAIL_VARIABLES_FAILURE = "GET_ALL_EMAIL_VARIABLES_FAILURE"
export const UPDATE_EMAIL_VARIABLES_SUCCESS = "UPDATE_EMAIL_VARIABLES_SUCCESS"
export const UPDATE_EMAIL_VARIABLES_FAILURE = "UPDATE_EMAIL_VARIABLES_FAILURE"
export const GET_ALL_EMAIL_TEMPLATES_SUCCESS = "GET_ALL_EMAIL_TEMPLATES_SUCCESS"
export const GET_ALL_EMAIL_TEMPLATES_FAILURE = "GET_ALL_EMAIL_TEMPLATES_FAILURE"
export const GET_EMAIL_VARIABLE_SUCCESS = "GET_EMAIL_VARIABLE_SUCCESS"
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = "UPDATE_EMAIL_TEMPLATE_SUCCESS"
export const UPDATE_EMAIL_TEMPLATE_FAILURE = "UPDATE_EMAIL_TEMPLATE_FAILURE"

// Groups
export const CREATE_GROUP_FAILURE = "CREATE_GROUP_FAILURE"
export const GET_ALL_GROUPS = "GET_ALL_GROUPS"
export const GET_ALL_GROUPS_FAILURE = "GET_ALL_GROUPS_FAILURE"
export const DELETE_GROUP = "DELETE_GROUP"
export const DELETE_GROUP_FAILURE = "DELETE_GROUP_FAILURE"

// Subscription
export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION"
export const CREATE_SUBSCRIPTION_FAILURE = "CREATE_SUBSCRIPTION_FAILURE"
export const UPDATE_SUBSCRIPTIONS = "UPDATE_SUBSCRIPTIONS"
export const UPDATE_SUBSCRIPTIONS_FAILURE = "UPDATE_SUBSCRIPTIONS_FAILURE"
export const GET_ALL_SUBSCRIPTION = "GET_ALL_SUBSCRIPTION"
export const GET_ALL_SUBSCRIPTION_FAILURE = "GET_ALL_SUBSCRIPTION_FAILURE"

// Attendees
export const CREATE_ATTENDEE_FAILURE = "CREATE_ATTENDEE_FAILURE"
export const GET_ALL_ATTENDEES = "GET_ALL_ATTENDEES"
export const GET_ALL_ATTENDEES_FAILURE = "GET_ALL_ATTENDEES_FAILURE"
export const DELETE_ATTENDEE = "DELETE_ATTENDEE"
export const DELETE_ATTENDEE_FAILURE = "DELETE_ATTENDEE_FAILURE"