import * as types from "../../constants";

const initialState = {
  questions: [],
  approved: [],
  answered: [],
  deleted: [],
  questionAndAnswers: [],
  chats: [],
  csvData: []
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.GET_ALL_QUESTIONS_SUCCESS: {
      return {
        ...state,
        questions: actions.questions
      };
    }
    case types.GET_ALL_APPROVED_SUCCESS: {
      return {
        ...state,
        approved: actions.approved
      };
    }
    case types.GET_ALL_ANSWERED_SUCCESS: {
      return {
        ...state,
        answered: actions.answered
      };
    }
    case types.GET_ALL_DELETED_SUCCESS: {
      return {
        ...state,
        deleted: actions.deleted
      };
    }
    case types.GET_ALL_QNAS_SUCCESS: {
      return {
        ...state,
        questionAndAnswers: actions.questionAndAnswers
      };
    }
      case types.SAVE_QUESTION_TEXT_SUCCESS: {
      return {
        ...state,
        questions: actions.questions
      };
    }
    case types.SAVE_APPROVED_TEXT_SUCCESS: {
      return {
        ...state,
        approved: actions.approved
      };
    }
    case types.SAVE_QUESTION_ANSWER_SUCCESS: {
      return {
        ...state,
        questions: actions.questions
      };
    }
    case types.SAVE_APPROVED_ANSWER_SUCCESS: {
      return {
        ...state,
        approved: actions.approved
      };
    }
    case types.DELETE_QNA_SUCCESS: {
      return {
        ...state,
        questionAndAnswers: actions.questionAndAnswers
      };
    }
    case types.GET_CHATS_SUCCESS: {
      return {
        ...state,
        chats: actions.chats
      };
    }
    case types.APPEND_QUESTION_TO_STATE_SUCCESS: {
      return {
        ...state,
        questions: actions.questions
      };
    }
    case types.QNAS_CSV_DATA: {
      return {
        ...state,
        csvData: actions.csvData
      };
    }
    default: {
      return state
    }
  }
}
