// import * as types from "../../constants";

// export default function reducer(state = {}, actions) {
//   switch (actions.type) {
//     case types.SET_ROOM_ID: {
//       return {
//         ...state,
//         roomId: actions.roomId
//       };
//     }
//     case types.SET_PRIVATE_CHAT_STATUS: {
//       return {
//         ...state,
//         privateChatStatus: actions.privateChatStatus
//       };
//     }
//     case types.GET_ALL_CHATS_SUCCESS: {
//       return {
//         ...state,
//         chats: actions.chats
//       };
//     }
//     case types.SHOW_REPLY_INPUT: {
//       return {
//         ...state,
//         replyToChatData: actions.replyToChat,
//         showReplyInput: actions.status
//       };
//     }
//     case types.DELETE_CHAT: {
//       return {
//         ...state,
//         chats: actions.chats,
//         deleteRecord: false
//       };
//     }
//     case types.ENABLE_DELETE_CHAT: {
//       return {
//         ...state,
//         deleteRecord: true
//       };
//     }
//     case types.GET_ALL_USERS_SUCCESS: {
//       return {
//         ...state,
//         users: actions.users
//       };
//     }
//     default: {
//       return {
//         ...state,
//         roomId: '',
//         chats: '',
//         replyToChatData: '',
//         showReplyInput: false,
//         users: '',
//         privateChatStatus: false,
//       };
//     }
//   }
// }
