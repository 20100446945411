import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { rootReducer } from "../reducers/index";

// // persist store code
// const loadState = () => {
//   try {
//     const serializedState = sessionStorage.getItem('state');
//     if (serializedState === null) {
//       return undefined;
//     }
//     return JSON.parse(serializedState);
//   } catch (e) {
//     return undefined;
//   }
// };

// const saveState = (state) => {
//   try {
//     const serializedState = JSON.stringify(state);
//     sessionStorage.setItem('state', serializedState);
//   } catch (e) {
//     // Ignore write errors;
//   }
// };

// const persistedState = loadState();

const composeEnhancers = composeWithDevTools({});

// const store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(thunk)));
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
// store.subscribe(() => {
//   saveState(store.getState());
// });

export default store;
