export const customBlue = {
  50: "#e9f0fb",
  100: "#c8daf4",
  200: "#a3c1ed",
  300: "#7ea8e5",
  400: "#6395e0",
  // 500: "#4782da",
  500: "#233044",
  600: "#407ad6",
  // 700: "#376fd0",
  700: "#ffcf45",
  800: "#2f65cb",
  900: "#2052c2 ",
};