import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import authReducer from "./authReducer";
import chatReducer from "./chatReducer";
import formsReducer from './formsReducer';
import eventReducer from './eventReducer';
import votesReducer from './votesReducer';
import questionAndAnswerReducer from './questionAndAnswerReducer';
import videoLibraryReducer from './videoLibraryReducer';
import organiserReducer from './organiserReducer';
import analyticsReducer from './analyticsReducer';
import emailReducer from './emailReducer';
import groupsReducer from './groupsReducer';
import subscriptionReducer from './subscriptionReducer';
import attendeesReducer from './attendeesReducer';

export const rootReducer = combineReducers({
  themeReducer,
  authReducer,
  chatReducer,
  formsReducer,
  eventReducer,
  votesReducer,
  questionAndAnswerReducer,
  videoLibraryReducer,
  organiserReducer,
  analyticsReducer,
  emailReducer,
  groupsReducer,
  subscriptionReducer,
  attendeesReducer
});
