import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components/macro";
import { darken } from "polished";
import { Search as SearchIcon } from "react-feather";
import { useSelector } from "react-redux";

import {
  Grid,
  Hidden,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Typography,
  Switch
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { Menu as MenuIcon } from "@material-ui/icons";
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@material-ui/icons/Send';
// import NotificationsDropdown from "./NotificationsDropdown";
// import MessagesDropdown from "./MessagesDropdown";
// import LanguagesDropdown from "./LanguagesDropdown";
import UserDropdown from "./UserDropdown";
import {getStatusOfAMS, startAMS, stopAMS} from '../services/organiserService'
// import { setIn } from "formik";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

// const Search = styled.div`
//   border-radius: 2px;
//   background-color: ${(props) => props.theme.header.background};
//   display: none;
//   position: relative;
//   width: 100%;
//   &:hover {
//     background-color: ${(props) => darken(0.05, props.theme.header.background)};
//   }
//   ${(props) => props.theme.breakpoints.up("md")} {
//     display: block;
//   }
// `;

// const SearchIconWrapper = styled.div`
//   width: 50px;
//   height: 100%;
//   position: absolute;
//   pointer-events: none;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   svg {
//     width: 22px;
//     height: 22px;
//   }
// `;

// const Input = styled(InputBase)`
//   color: inherit;
//   width: 100%;
//   > input {
//     color: ${(props) => props.theme.header.search.color};
//     padding-top: ${(props) => props.theme.spacing(2.5)}px;
//     padding-right: ${(props) => props.theme.spacing(2.5)}px;
//     padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
//     padding-left: ${(props) => props.theme.spacing(12)}px;
//     width: 160px;
//   }
// `;

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 52,
    height: 22,
    padding: 0,
    display: 'flex',
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(30px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#52d869',
        borderColor: '#52d869',
      },
    },
    
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 22 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
}))(Switch);

const AppBarComponent = ({ onDrawerToggle }) => {

  const auth = useSelector((state) => state.authReducer);

  const [serverEnabled, setServerEnabled] = useState(false)
  const [showServer, setShowServer] = useState(false)
  const [serverStatus, setServerStatus] = useState('')

  useEffect(() => {
    if (auth?.user?.user_details?.role[0]?.name === 'admin') {
      setShowServer(true)
    }
    let myTimer = setInterval(async () => {
      const status = await getStatusOfAMS()
      setServerStatus(status.status)
      if (status.status === 'starting' || status.status === 'started') setServerEnabled(true)
      if (status.status === 'stopping' || status.status === 'stopped') setServerEnabled(false)
      if(status.status === 'started' || status.status === 'stopped') clearInterval(myTimer);
    }, 5000)
  }, [serverEnabled])

  const onChangeHandler = (event) => {
    setServerEnabled(event.target.checked)
    if (event.target.checked) {
      startAMS()
      setServerEnabled(true)
      setServerStatus('starting')
    }
    if (!event.target.checked) {
      stopAMS()
      setServerEnabled(false)
      setServerStatus('stopping')
    }
  }

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item>
              {/* <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input placeholder="Search topics" />
              </Search> */}
              
              <div>
                {
                  showServer &&
                  <Typography component="div" style={{ color: '#000000de', marginBottom: 10 }}>
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item><b style={{ marginRight: '14px' }}>AMS Server: </b></Grid>
                      <Grid item>Off</Grid>
                      <Grid item>
                        <AntSwitch disabled={serverStatus === '' || serverStatus === 'starting' || serverStatus === 'stopping'} checked={serverEnabled} onChange={onChangeHandler} />
                      </Grid>
                      <Grid item>On</Grid>
                    </Grid>
                  </Typography>
                }
                  <Typography component="div" style={{ color: '#000000de' }}>
                      <b>Video Server:</b> &nbsp;
                      {serverStatus === '' &&
                        <LoadingButton
                          size="small"
                          loading={serverStatus === ''}
                          variant="text"
                          color="primary"
                        >
                          checking
                        </LoadingButton>
                      }
                      {serverStatus === 'starting' &&
                        <LoadingButton
                          size="small"
                          loading={serverStatus === 'starting'}
                          variant="text"
                          color="primary"
                          endIcon={<SendIcon />}
                          loadingPosition="end"
                        >
                          starting
                        </LoadingButton>
                      }
                      {serverStatus === 'started' &&
                        <span style={{ color: '#00a71d', fontWeight: 'bold', textTransform: 'uppercase' }}>Running</span>
                      }
                      {serverStatus === 'stopping' &&
                        <LoadingButton
                          size="small"
                          loading={serverStatus === 'stopping'}
                          variant="text"
                          color="primary"
                          endIcon={<SendIcon />}
                          loadingPosition="end"
                        >
                          stopping
                        </LoadingButton>
                      }
                      {serverStatus === 'stopped' &&
                        <span style={{ color: '#d90000', fontWeight: 'bold', textTransform: 'uppercase' }}>Offline</span>
                      }
                  </Typography>
                </div>
            </Grid>
            <Grid item xs />
            <Grid item style={{ display: "flex", alignItems:"center" }} >
              {/* <MessagesDropdown />
              <NotificationsDropdown />
              <LanguagesDropdown /> */}
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
};

export default withTheme(AppBarComponent);
