import * as types from "../../constants";

const initialState = {
  groups: [],
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.GET_ALL_GROUPS: {
      return {
        ...state,
        groups: actions.groups
      };
    }
    case types.DELETE_GROUP: {
      return {
        ...state,
        groups: actions.groups
      };
    }
    default: {
      return state
    }
  }
}
