import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.CREATE_GRAPH_DATA: {
      return {
        ...state,
        graphData: actions.graphData
      };
    }
    case types.GET_GRAPH_DATA: {
      return {
        ...state,
        event: actions.event,
        graphData: actions.graphData,
        peakViewCount: actions.peakViewCount,
        viewers: actions.viewers,
        currentAttendees: actions.currentAttendees,
        notShowedUpAttendees: actions.notShowedUpAttendees
      };
    }
    case types.GET_SUMMARY_DATA: {
      return {
        ...state,
        attendees: actions.attendees,
        totalCount: actions.totalCount
      };
    }
    default: {
      return {
        ...state,
        event: '',
        graphData: [],
        peakViewCount: 0,
        viewers: 0,
        currentAttendees: [],
        notShowedUpAttendees: [],
        attendees: [],
        totalCount: 0
      };
    }
  }
}